.progress_point_container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    height: 100%;
}

.range_values {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 6px;
}

.range_values span {
    font-size: 10px;
    color: gray;
}

.range_values span:nth-child(2) {
    font-weight: bold;
    color: #007bff;
}

.progress_line {
    position: relative;
    width: 100%;
    height: 2px;
    background-color: #e5e5e5;
    border-radius: 5px;
}

.progress_point {
    position: absolute;
    top: -2px;
    width: 6px;
    height: 6px;
    background-color: gray;
    border-radius: 50%;
    transform: translateX(-50%);
}