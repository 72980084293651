.card {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.heading {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 1.125rem;
}

.chart {
    width: 100%;
    height: 100%;
}

.card p {
    color: gray;
    font-size: 14px;
}

.card p b {
    color: black;
}

.chart {
    display: flex;
    align-items: end;
    padding: 20px 20px 10px;
}

.barChart {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.barChart span:first-child {
    font-weight: 600;
}

.barChart div {
    width: 40px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.barChart span {
    font-size: 12px;
    text-align: center;
}